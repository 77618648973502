<template>
    <div class="page-service-inventory">
        <div class="container">
            <div class="row">
                <div class="col-12 heading-box">
                    <h1 class="heading">Service Inventory</h1>

                    <div class="dropdown-select-box">
                        <span>Service type:</span>

                        <app-dropdown-select
                            v-model="service_type"
                            :options="service_types"

                            key-value="value"
                            key-title="title"

                            @change="onChangeServiceType"
                        />
                    </div>
                </div>

                <div class="col-12">
                    <app-loader v-if="loading" fixed-on="desktop" />

                    <app-error v-model="errors.show" :message="errors.message" />

                    <app-search-box
                        v-model="filter.word"

                        @search="onSearch"
                    />

                    <app-table
                        class="service-inventory-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="rows"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appDropdownSelect from '@/components/app-dropdown-select'
import appSearchBox from '@/components/app-search-box'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        appLoader,
        appError,
        appTable,
        appPagination,
        appDropdownSelect,
        appSearchBox,
    },

    data() {
        return {
            service_type: null,

            filter: {
                word: '',

                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc',
                },
            },

            sort: [
                { field: 'ServiceType',    title: 'Service type' },
                { field: 'CreatedAtNanos', title: 'Created at'   },
            ],

            cols: [
                { key: 'uuid',                title: 'UUID',                 highlight: true                                   },
                { key: 'ServiceType',         title: 'Service type',                         sort: { field: 'ServiceType'    } },
                { key: 'Name',                title: 'Name'                                                                    },
                { key: 'CreatedAtNanos',      title: 'Created at',                           sort: { field: 'CreatedAtNanos' } },
            ],

            service_catalog_entries: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getServiceCatalogEntries()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getServiceCatalogEntries()
        },

        onPageChange(page) {
            this.getServiceCatalogEntries(page)
        },

        getServiceCatalogEntries(page) {
            this.loading = true

            const params = {
                SPID: this.current_spid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            if (this.filter.word) {
                params.SmartSearch = this.filter.word
            }

            if (this.service_type) {
                params.ServiceType = this.service_type
            }

            console.log('params', params)

            this.$store.dispatch('api_service_catalog/FindServiceCatalogEntriesPaginated', params)
                .then(response => {
                    if (!response.ServiceCatalogEntries || !response.PageInfo) {
                        return Promise.reject(response)
                    }

                    this.service_catalog_entries = response.ServiceCatalogEntries

                    this.pagination.page = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            query: this.$route.query,
                            replace: true
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.service_catalog_entries = []

                    this.loading = false
                })
        },

        onSearch() {
            this.getServiceCatalogEntries(1)
        },

        onChangeServiceType() {
            this.getServiceCatalogEntries(1)
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',

            'services_adding_types',
        ]),

        rows() {
            return this.service_catalog_entries.map(service_catalog_entry => ({
                uuid: service_catalog_entry.UUID,

                ServiceType: service_catalog_entry.ServiceType ? service_catalog_entry.ServiceType : '?',
                Name: service_catalog_entry.Name ? service_catalog_entry.Name : '?',
                CreatedAtNanos: service_catalog_entry.CreatedAtNanos ? formatDateNano(service_catalog_entry.CreatedAtNanos) : '?',
            }))
        },

        service_types() {
            const unique_service_types = {}
            const service_types = []

            for (const key in this.services_adding_types) {
                unique_service_types[this.services_adding_types[key].service_type] = {
                    value: this.services_adding_types[key].service_type,
                    title: this.services_adding_types[key].service_type,
                }
            }

            for (const key in unique_service_types) {
                service_types.push(unique_service_types[key])
            }

            return service_types
        },
    },
}
</script>

<style lang="scss">
.page-service-inventory {
    padding-bottom: 80px;

    .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 48px;

        .dropdown-select-box {
            display: flex;
            align-items: center;

            width: 100%;
            max-width: 425px;

            & > span {
                flex-shrink: 0;

                margin-right: 16px;

                font-size: 18px;
                color: var(--color-table-secondary);
            }

            // .app-dropdown-select {}
        }
    }

    .app-error,
    .app-search-box {
        margin-bottom: 24px;
    }

    .service-inventory-table {
        @include table-cols-width((325px, 150px, 225px, 150px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

/*
    @media (max-width: $tablet-size) {
        .page-service-inventory {}
    }
*/

/*
    @media (max-width: $mobile-size) {
        .page-service-inventory {}
    }
*/
</style>